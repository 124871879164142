import Vue from "vue";
import Vuex from "vuex";
import api from "./utils/api";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    config: {},
    store: {},
    billing: {},
    subscribers: {},
    templates: [],
    orderTemplates: [],
    orders: {}
  },
  mutations: {
    SET_CONFIG: (state, payload) => {
      state.config = payload;
    },
    SET_STORE: (state, payload) => {
      state.store = payload;
    },
    SET_BILLING: (state, payload) => {
      state.billing = payload;
    },
    SET_SUBSCRIBERS: (state, payload) => {
      state.subscribers = payload;
    },
    SET_ORDERS: (state, payload) => {
      state.orders = payload;
    },
    SET_TEMPLATES: (state, payload) => {
      state.templates = payload;
    },
    SET_ORDER_TEMPLATES: (state, payload) => {
      state.orderTemplates = payload;
    },
    UPDATE_SUBSCRIBER: (state, payload) => {
      const subscriberIndex = state.subscribers.docs.findIndex(
        checkout => checkout.id === payload.subscriberId
      );
      state.subscribers.docs[subscriberIndex] = {
        ...state.subscribers.docs[subscriberIndex],
        isMessageSent: true
      };
      state.subscribers = { ...state.subscribers };
      //Vue.set(state.checkouts, "docs", state.checkouts.docs);
    },
    UPDATE_ORDER: (state, payload) => {
      const orderIndex = state.orders.docs.findIndex(
        order => order.id === payload.orderId
      );
      state.orders.docs[orderIndex] = {
        ...state.orders.docs[orderIndex],
        isMessageSent: true
      };
      state.orders = { ...state.orders };
      //Vue.set(state.orders, "docs", state.orders.docs);
    },
    UPDATE_TAG: (state, payload) => {
      const checkoutIndex = state.checkouts.docs.findIndex(
        checkout => checkout.id === payload.checkoutId
      );
      const docs = state.checkouts.docs;
      docs[checkoutIndex] = {
        ...docs[checkoutIndex],
        checkoutMeta: { ...payload.customerMeta }
      };

      // Vue.set(state.checkouts, "docs", docs);
      // state.checkouts = { ...state.checkouts, docs };
    },
    updateConfig: (state, payload) => {
      Vue.set(state.config, payload.key, payload.value);
    },
    updateTextsInConfig(state, payload) {
      Vue.set(state.config.texts, payload.key, payload.value);
    }
  },
  getters: {
    getConfig: state => state.config,
    getStore: state => state.store,
    getSubscribers: state => state.subscribers,
    getTemplates: state => state.templates,
    getBilling: state => state.billing
  },
  actions: {
    login(context, payload) {
      return api.get(`shopify/shop/login?shop=${payload.domain}`);
    },
    getConfig(context) {
      return api.get(`shopify/config`).then(response => {
        context.commit("SET_STORE", response.store);
        return response;
      });
    },
    getAppConfig(context) {
      return api.get(`app/config`).then(response => {
        context.commit("SET_CONFIG", response.config);
        return response;
      });
    },
    updateAppConfig(context, payload) {
      return api.put(`app/config`, payload.requestParams);
    },
    activateBar() {
      return api.post(`app/activate`);
    },
    deactivateBar() {
      return api.post(`app/deactivate`);
    },
    getEmbedUrl() {
      return api.get(`app/embedurl`);
    },
    getAnalytics(context, payload) {
      return api.get(
        `app/analytics?startDate=${payload.startDate}&endDate=${payload.endDate}`
      );
    }
  },
  modules: {}
});
