import axios from "axios";

axios.interceptors.request.use(
  config => {
    const newConfig = config;
    let authToken = null;
    try {
      if (typeof window.localStorage !== "undefined") {
        authToken = window.localStorage.getItem("authToken");
      }
    } catch (err) {
      authToken = null;
    }

    if (authToken) {
      // Send token to your backend via HTTPS
      // ...
      newConfig.headers.post.Authorization = authToken;
      newConfig.headers.get.Authorization = authToken;
      newConfig.headers.put.Authorization = authToken;
      newConfig.headers.delete.Authorization = authToken;
      return Promise.resolve(newConfig);
    }
    return newConfig;
  },
  error =>
    // Do something with request error
    Promise.reject(error)
);

export default {
  post(url, request) {
    return axios
      .post(`${window.serverURL}${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  get(url) {
    return axios
      .get(`${window.serverURL}${url}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  put(url, request) {
    return axios
      .put(`${window.serverURL}${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  delete(url, request) {
    return axios
      .delete(`${window.serverURL}${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  postSkipAuth(url, request) {
    return axios
      .post(`${window.serverURL}${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
};
